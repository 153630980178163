<template>

  <div class="mt-3 mb-3 full-width">
    <div class="d-flex">
      <div
        :class="{'full-width': !editar}"
        class="style-box pointer"
        @click="show = !show"
      >
        <span>{{data[label]}}</span>
        <span class="float-right mr-3">
          <i
            class="pointer"
            :class=" !show? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
          />
        </span>
      </div>

      <span
        class="ml-auto"
        v-if="editar"
      >
        <el-button
          type="primary"
          icon="el-icon-edit"
          circle
          @click="$emit('edit', data)"
        />
        <el-button
          type="danger"
          icon="el-icon-delete"
          circle
          @click="$emit('delete', data)"
        />
      </span>
    </div>
    <transition name="fade">
      <div
        class="backgroud-body"
        v-show="show"
      >
        <slot />
      </div>
    </transition>
  </div>
</template>
<script>

export default {

  props: {
    label: {
      type: String,
    },
    data: {
      required: true,
    },
    editar: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {

  },
};
</script>

<style scoped>
.visualizations__body {
	padding: 0.4rem;
}

.pointer {
	cursor: pointer;
}
.style-box {
	width: 90%;
}
.full-width{
  width: 100% !important;
}
.float-right {
	float: right !important;
}
.backgroud-body {
	background-color: #ccc;
	width: 90%;
	border-radius: 3px;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
}

.fade-leave,
.fade-enter-to {
	opacity: 2;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 500ms;
}
</style>
